.blog-date {
  font-size: .75em;
  color: gray;
}

.blog-pager {
  display: flex;
  justify-content: space-between;
  margin: 0 -5px;
  padding: 0;
  list-style: none;
  li {
    padding: 5px;
  }
  .next {
    float: right;
  }
  .previous {
    float: left;
  }
}

.blog-list-heading {
  font-size: $font-size-h3;
  @include heading();
  @media(min-width: $tablet-size) {
    font-size: $font-size-h2;
  }
}

.blog-title {
  font-size: $font-size-h3;
  @include heading();
  @media(min-width: $tablet-size) {
    font-size: $font-size-h2;
  }
  small {
    font-size: $font-size-base;
  }
}

.blog-list-title {
  font-size: $font-size-h4;
  @include heading();
  @media(min-width: $tablet-size) {
    font-size: $font-size-h3;
  }
}

.blog-content img {
  padding: 16px 0;
  display: block;
  margin: 0 auto;
}

.blog-tags {
  @include box-list($brand-primary, $gray-lighter);
  margin-top: 20px;
}
