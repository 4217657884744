@mixin flexbox() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-align-items($value) {
  -webkit-box-align: $value;
  -ms-flex-align: $value;
  align-items: $value;
}

@mixin flex-wrap($value) {
  -ms-flex-wrap: $value;
  flex-wrap: $value;
}

@mixin flex-grow($value) {
  -webkit-box-flex: $value;
  -ms-flex-positive: $value;
  flex-grow: $value;
}

@mixin flex-horizontal-center() {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin flex-horizontal-left() {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin flex-horizontal-right() {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
