.l-header {
  background-color: rgba($brand-primary, 0.80);
  color: $body-bg;
}

.l-footer {
  margin-top: 30px;
  .center {
    text-align: center;
    margin-top: 10px;
    color: $link-color;
  }
  a {
    @include constant-link($link-color);
  }
}

.l-main {
  @include main();
}

.l-content {
  @include col(85%, $small-computer-size);
  @include col(70%, $large-computer-size);
}

.row {
  @include row();
}

.lead {
  @include lead();
}

.page-title {
  margin-top: $line-height-computed;
}
