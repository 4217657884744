@mixin base() {
  height: 100%;
  margin: 0;
}

html {
  @include base();
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  @include base();
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
}

img {
  vertical-align: middle;
  max-width: 100%
}

a {
  color: $link-color;
  text-decoration: none;
  &:hover,
  &:focus {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &:visited {
    color: $link-hover-color;
  }
}

hr {
  margin-top: $line-height-computed;
  margin-bottom: $line-height-computed;
  border: 0;
  border-top: 1px solid $gray-lighter;
}

p {
  margin: 0 0 ($line-height-computed / 2);
}

ul {
  margin-top: 0;
  margin-bottom: ($line-height-computed / 2);
}

@mixin code() {
  background-color: $code-bg;
  border-radius: 3px;
  font-family: $code-font;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  @include code();
}

pre {
  display: block;
  padding: (($line-height-computed - 1) / 2);
  margin: 0 0 ($line-height-computed / 2);
  font-size: ($font-size-base - 1); // 14px to 13px
  line-height: $line-height-base;
  word-break: break-all;
  word-wrap: break-word;
  border: 1px solid $gray-lightish;
  @include code();

  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre;
    word-wrap: normal;
    border-radius: 0;
  }
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: ($font-size-base * 1.25);
  border-left: 5px solid $gray-light;
}
