// colors
$aquamarine: #017890;
$brand-primary: $aquamarine;
$gray-base: #000;
$gray-dark: lighten($gray-base, 20%);
$gray-light: lighten($gray-base, 46.7%);
$gray-lightish: lighten($gray-base, 85%);
$gray-lighter: lighten($gray-base, 93.5%);
$white: #fff;
$code-bg: $white;
$body-bg: #fafafa;
$link-color: lighten($brand-primary, 15%);
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;
$text-color: $gray-dark;

// fonts
$font-family-serif: Palatino, Times, serif;
$font-family-sans-serif: Verdana, Geneva, sans-serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base: $font-family-sans-serif;
$code-font: $font-family-monospace;

$font-size-base: 14px;

$font-size-h1: floor(($font-size-base * 2.6));
$font-size-h2: floor(($font-size-base * 2.15));
$font-size-h3: ceil(($font-size-base * 1.7));
$font-size-h4: ceil(($font-size-base * 1.25));
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85));

$line-height-base: 1.428571429;
$line-height-computed: floor(($font-size-base * $line-height-base));

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;
$headings-color: inherit;
