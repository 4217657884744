.resume-title {
  text-align: center;
}

.resume-section {
  @include row();
  .section-title {
    @include col(17%, $small-computer-size);
    h1 {
      @include heading-small();
      font-size: $font-size-h4;
      font-weight: 700;
      @media(min-width: $small-computer-size) {
        font-weight: $headings-font-weight;
      }
    }
  }
  .section-body {
    @include col(83%, $small-computer-size);
    h2 {
      @include heading-small();
      font-size: $font-size-h4;
    }
    h3 {
      @include heading-small();
      font-size: $font-size-h5;
    }
  }
}

.skill-list-container {
  @include row();
}

.skill-list {
  @include col(50%, $tablet-size);
}
