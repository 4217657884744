@mixin lead() {
  margin: 0 0 10px;
  font-size: floor($font-size-base * 1.15);
  margin-bottom: $line-height-computed;
  font-weight: 300;
  line-height: 1.4;
  @media (min-width: $tablet-size) {
    font-size: $font-size-base * 1.5;
  }
}

@mixin heading-shared() {
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  margin-bottom: ($line-height-computed / 2);
}

@mixin heading() {
  @include heading-shared();
  margin-top: $line-height-computed;
}

@mixin heading-small() {
  @include heading-shared();
  margin-top: ($line-height-computed / 2);
}

@mixin constant-link($color) {
  text-decoration: none;
  color: $color;
  &:hover, &:focus, &:visited {
    text-decoration: none;
    color: $color;
  }
}

@mixin box-link($color, $hover-background) {
  @include constant-link($color);
  padding: 5px 10px;
  border: 1px solid $color;
  margin: 2px;
  &:hover {
    background: $hover-background;
  }
}

@mixin box-list($link-color, $link-hover-background) {
  @include flexbox();
  @include flex-wrap(wrap);
  list-style: none;
  padding: 0;
  li {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  a {
    @include box-link($link-color, $link-hover-background);
  }
}
